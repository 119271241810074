<template>
  <div class="app-container">
    <el-tabs
        v-model="stepName"
        type="border-card"
        @tab-click="handleClick"
    >
      <el-tab-pane
          v-if="checkPermission(['ADMIN','MERCHANTINFO_ALL','MERCHANTINFO_SELECT'])"
          label="全部列表"
          name="all"
      >
        <table-list
            :step-name="stepName"
        ></table-list>
      </el-tab-pane>
      <el-tab-pane
          v-if="checkPermission(['ADMIN','MERCHANTINFO_ALL','MERCHANTINFO_SELECT'])"
          label="待审核"
          name="waitAudit"
      >
        <table-list
            :step-name="stepName"
        ></table-list>
      </el-tab-pane>
      <el-tab-pane
          label="已完成"
          v-if="checkPermission(['ADMIN','MERCHANTINFO_ALL','MERCHANTINFO_SELECT'])"
          name="finish"
      >
        <table-list
            :step-name="stepName"
        ></table-list>
      </el-tab-pane>
      <el-tab-pane
          v-if="checkPermission(['ADMIN','MERCHANTINFO_ALL','MERCHANTINFO_SELECT'])"
          label="已拒绝"
          name="reject"
      >
        <table-list
            :step-name="stepName"
        ></table-list>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import tableList from '@/views/pt/intergratingManage/exchangeRecord/tableList'
export default {
  name: 'index',
  components:{
    tableList,
  },
  data() {
    return {
      stepName:'all',
    }
  },
  methods: {
    checkPermission,
    handleClick(tab, event) {

    }
  }
}
</script>

<style scoped>

</style>
